import { Controller } from '@hotwired/stimulus'
import { enter, leave, toggle } from 'el-transition'

export default class extends Controller {
  static targets = ['item']
  static classes = ['hidden']

  connect () {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
  }

  toggle () {
    toggle(this.itemTarget)
  }

  show () {
    enter(this.itemTarget)
  }

  hide () {
    if (this.clickedOutside || this.escapePressed) {
      leave(this.itemTarget)
    }
  }

  get clickedOutside () {
    return !this.element.contains(event.target) && !this.itemTarget.classList.contains('hidden')
  }

  get escapePressed () {
    return event instanceof KeyboardEvent && event.key === 'Escape'
  }
}
