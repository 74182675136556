import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['qrImg', 'svgLogo', 'preview']
  static values = {
    text: String,
    fileName: {
      type: String,
      default: 'qr_code.png'
    },
    padding: {
      type: Number,
      default: 40
    },
    paddingBotton: {
      type: Number,
      default: 20
    },
    backgroundColor: {
      type: String,
      default: '#F8FAFC'
    },
    textColor: {
      type: String,
      default: '#1e293b'
    }
  }

  async download () {
    const { canvas, ctx } = this.imagenToCanvas()
    await this.drawLogo(ctx, canvas)
    this.drawBottomText(ctx, canvas)
    const link = document.createElement('a')
    link.href = canvas.toDataURL('image/png')
    link.download = this.fileNameValue
    link.click()
    this.dispatch('downloaded', { detail: { content: '' } })
  }

  imagenToCanvas () {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = this.qrImgTarget
    ctx.imageSmoothingEnabled = false
    canvas.width = (img.width + this.paddingValue)
    canvas.height = (img.height + this.paddingValue + this.paddingBottonValue)
    ctx.fillStyle = this.backgroundColorValue
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(img, this.paddingValue / 2, this.paddingValue / 2, img.width, img.height)
    return { canvas, ctx }
  }

  drawBottomText (ctx, canvas) {
    ctx.font = 'bold 16px Inter'
    ctx.textAlign = 'center'
    ctx.fillStyle = this.textColorValue
    ctx.fillText(this.textValue, canvas.width / 2, canvas.height - this.paddingBottonValue / 2)
  }

  drawLogo (ctx, canvas) {
    if (!this.hasSvgLogoTarget) return false
    return this.#drawLogoCented(ctx, canvas)
  }

  #drawLogoCented (ctx, canvas) {
    return new Promise((resolve) => {
      const img = new Image()
      const svgString = new XMLSerializer().serializeToString(this.svgLogoTarget)
      const svgDataUri = 'data:image/svg+xml;base64,' + btoa(svgString)
      const logoBackgroundWidth = 71
      const logoBackgroundHeight = 63
      const logoBackgroundSpaceY = 82
      const logoWidth = 57
      const logoHeight = 40
      const logoSpaceX = 60
      const logoSpaceY = 58
      img.src = svgDataUri
      img.onload = () => {
        ctx.fillStyle = 'white'
        ctx.fillRect((canvas.width - logoBackgroundWidth) / 2, (canvas.height - logoBackgroundSpaceY) / 2, logoBackgroundWidth, logoBackgroundHeight)
        ctx.drawImage(img, (canvas.width - logoSpaceX) / 2, (canvas.height - logoSpaceY) / 2, logoWidth, logoHeight)
        resolve()
      }
    })
  }
}
