import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static template = (name, value, label) => `<div class="rounded-full border border-gray-400 bg-white inline-flex items-center px-2 py-1 mb-0.5" data-controller="selected-option">
    <span class="text-xs leading-none text-gray-800 pr-1">${escapeHtml(label)}</span>
    <input type="hidden" name="${escapeHtml(name)}[]" value="${escapeHtml(value)}">
    <button class="inline-flex focus:outline-none -mr-0.5" data-action="selected-option#remove">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 text-gray-800">
        <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22z"/>
      </svg>
    </button>
    </div>
  `

  static addTo (selectedOptionsContainer, name, value, label) {
    if (this.alreadySelected(selectedOptionsContainer, value)) return

    selectedOptionsContainer.insertAdjacentHTML('beforeend', this.template(name, value, label))
  }

  static alreadySelected (selectedOptionsContainer, value) {
    return selectedOptionsContainer.querySelector(`input[value="${value}"]`)
  }

  remove () {
    this.element.remove()
  }
}

const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}
