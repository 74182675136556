import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['notification']
  static classes = ['show', 'hide']

  toggleNotification () {
    this.notificationTarget.classList.remove(this.hideClass)
    this.notificationTarget.classList.add(this.showClass)
    setTimeout(() => {
      this.notificationTarget.classList.remove(this.showClass)
      this.notificationTarget.classList.add(this.hideClass)
    }, 1000)
  }
}
