import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  handleSubmit (object) {
    const response = object.detail.fetchResponse.response
    const contentType = response.headers.get('content-type')

    if (contentType && contentType.includes('application/pdf')) {
      this.openPdfInNewTab(response)
    }
  }

  async openPdfInNewTab (response) {
    try {
      const blob = await response.blob()
      const pdfUrl = URL.createObjectURL(blob)
      window.open(pdfUrl, '_blank')
    } catch (error) {
      console.error('Failed to open PDF:', error)
    }
  }
}
