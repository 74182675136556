import { Controller } from '@hotwired/stimulus'
import {
  constructFileName,
  searchAndShowInputAssociated,
  abbreviationSelected
} from '@/lib/file_upload_general'

export default class extends Controller {
  static targets = ['filename', 'filenameDB', 'type', 'input', 'uploadBox', 'typeTrigger']

  connect () {
    this.inputTarget.addEventListener('change', this.upload.bind(this))
    this.typeTargets.forEach(type => type.addEventListener('change', this.changeTypeSelected.bind(this)))
  }

  upload ({ target }) {
    const fileName = constructFileName(target.files[0].name, this.typeTargets)
    if (abbreviationSelected(this.typeTargets) == null) {
      this.assignTheFileName(target.files[0].name)
    } else {
      this.assignTheFileName(fileName)
      this.changeTypeSelected(this.element)
    }

    target.dataset.file = target.files[0].name
  }

  changeTypeSelected () {
    this.uploadBoxTarget.classList.remove('hidden')
    const inputNameUploaded = this.inputTarget.dataset.file

    if (inputNameUploaded) {
      const fileName = constructFileName(inputNameUploaded, this.typeTargets)
      this.assignTheFileName(fileName)
    }

    searchAndShowInputAssociated(this.typeTriggerTargets, this.typeTargets)
    this.createAndSendEvent(this.element)
  }

  // Private

  assignTheFileName (name) {
    this.filenameTarget.textContent = name
    this.filenameDBTarget.value = name
  }

  createAndSendEvent (element) {
    element.dispatchEvent(new CustomEvent('fileUploaded', {
      bubbles: true,
      detail: {
        input: this.inputTarget,
        abbreviationSelected: abbreviationSelected(this.typeTargets),
        fileUpdatedUI: this.filenameTarget
      }
    }))
  }
}
