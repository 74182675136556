import { Controller } from '@hotwired/stimulus'
import { easepick } from '@easepick/core'
import { RangePlugin } from '@easepick/range-plugin'
import { AmpPlugin } from '@easepick/amp-plugin'
import dayjs from 'dayjs'

export default class extends Controller {
  static targets = ['datePicker', 'startDate', 'endDate']
  static values = {
    startDate: { type: String, default: '' },
    endDate: { type: String, default: '' },
    hasRange: { type: Boolean, default: false },
    onModal: { type: Boolean, default: false }
  }

  connect () {
    this.initializeDatePicker()
  }

  initializeDatePicker () {
    if (!this.hasDatePickerTarget) return false

    // eslint-disable-next-line new-cap
    this.picker = new easepick.create({
      element: this.datePickerTarget,
      css: [
        'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.1/dist/index.css'
      ],
      date: this.date,
      setup: this.setup.bind(this),
      AmpPlugin: {
        dropdown: {
          months: true,
          years: true
        },
        darkMode: false
      },
      plugins: this.plugins,
      readonly: false,
      format: 'MM/DD/YYYY'
    })
  }

  setup (picker) {
    picker.on('render', () => {
      this.removeOverFlowOfModal()
    })

    picker.on('clear', () => {
      this.addOverFlowOfModal()
    })

    picker.on('select', (e) => {
      const { start, end } = e.detail

      if (this.hasStartDateTarget) this.startDateTarget.value = dayjs(start).format('YYYY-MM-DD')

      if (this.hasEndDateTarget) this.endDateTarget.value = dayjs(end).format('YYYY-MM-DD')
    })
  }

  get date () {
    return this.hasRange ? `${this.startDate} - ${this.endDate}` : this.datePickerTarget.value
  }

  get startDate () {
    return this.startDateValue
  }

  get endDate () {
    return this.endDateValue
  }

  get plugins () {
    const plugins = []
    if (this.hasRange) plugins.push(RangePlugin)
    plugins.push(AmpPlugin)
    return plugins
  }

  get onModal () {
    return this.onModalValue
  }

  get hasRange () {
    return this.hasRangeValue
  }

  get modalBodyElement () {
    return document.querySelector('div[data-modal-target="body"]')
  }

  addOverFlowOfModal () {
    if (!this.onModal) return false
    this.modalBodyElement.classList.add('overflow-hidden')
  }

  removeOverFlowOfModal () {
    if (!this.onModal) return false
    this.modalBodyElement.classList.remove('overflow-hidden')
  }
}
