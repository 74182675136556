import { Controller } from '@hotwired/stimulus'
import { MaskInput } from 'maska'

export default class extends Controller {
  static values = { pattern: String }

  connect () {
    if (this.element.dataset.inputType === 'ein/ssn') {
      const selectElement = this.element.parentElement.querySelector('select')
      selectElement.addEventListener('change', this.changeMaskEinSnn.bind(this))
    }

    this.mask = new MaskInput(this.element, {
      mask: this.maskPattern,
      tokens: {
        0: { pattern: /[0-9]/, multiple: true },
        9: { pattern: /[0-9]/, optional: true }
      }
    })
  }

  disconnect () {
    this.mask?.destroy()
  }

  changeMaskEinSnn (event) {
    const typeMask = event.target.value
    const inputMask = typeMask === 'EIN' ? '##-#######' : '###-##-####'

    this.mask = new MaskInput(this.element, { mask: inputMask })
  }

  get maskPattern () {
    if (this.patternValue === 'phone') {
      return (value) => value.startsWith('+5') ? '+57 (###) ###-####' : '+1 (###) ###-####'
    } else {
      return this.patternValue
    }
  }
}
