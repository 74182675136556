import { BridgeComponent } from '@hotwired/strada'

export default class extends BridgeComponent {
  static component = 'settings-button'

  connect () {
    super.connect()
    this.#notifyBridgeOfConnect()
  }

  #notifyBridgeOfConnect () {
    const element = this.bridgeElement
    const image = element.bridgeAttribute('image')
    const side = element.bridgeAttribute('side') || 'right'

    this.send('connect', { image, side }, () => {
      this.element.click()
    })
  }
}
