import Autocomplete from './autocomplete_controller'

export default class extends Autocomplete {
  static targets = ['address', 'usdot', 'icon']

  commit (selected) {
    if (selected.getAttribute('aria-disabled') === 'true') return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.close()
      return
    }

    const textValue = selected.getAttribute('data-autocomplete-label') || selected.textContent.trim()
    const address = selected.getAttribute('data-autocomplete-address')
    const usdot = selected.getAttribute('data-autocomplete-usdot')
    this.inputTarget.value = textValue

    if (this.hasAddressTarget && this.hasUsdotTarget) {
      this.addressTarget.value = address
      this.usdotTarget.value = usdot

      this.addressTarget.dispatchEvent(new Event('input'))
      this.usdotTarget.dispatchEvent(new Event('input'))
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(
      new CustomEvent('autocomplete.change', {
        bubbles: true,
        detail: { textValue, selected }
      })
    )
  }

  fetchResults = async (query) => {
    try {
      this.toggleSearchIcon()
      this.element.dispatchEvent(new CustomEvent('loadstart'))
      const html = this.hasOptionsValue ? this.fetchLocalOptions(query) : await this.fetchRemoteResults(query)

      this.replaceResults(html)
      this.element.dispatchEvent(new CustomEvent('load'))
      this.element.dispatchEvent(new CustomEvent('loadend'))
      this.toggleSearchIcon()
    } catch (error) {
      this.toggleSearchIcon()
      this.element.dispatchEvent(new CustomEvent('error'))
      this.element.dispatchEvent(new CustomEvent('loadend'))
      throw error
    }
  }

  toggleSearchIcon () {
    this.iconTarget.classList.toggle('hidden')
  }
}
