import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']
  static values = { frequency: String, recurrence: Array }

  initialize () {
    if (!this.hasCheckboxTarget) return

    this.checkboxTargets.forEach(element => {
      if (this.isDailyFrequency) {
        if (this.frequencyValue.includes(element.value)) element.checked = true
      } else {
        element.checked = true
      }
    })
  }

  get isDailyFrequency () {
    return this.frequencyValue === 'daily'
  }
}
