import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submitForm () {
    if (this.validForm) {
      this.element.requestSubmit()
    } else {
      this.showInvalidFields()
    }
  }

  showInvalidFields () {
    const formElements = Array.from(this.element.elements)

    formElements.forEach((field) => {
      if (!field.validity.valid) {
        this.makeDetailsVisible(field.closest('details'))
        this.element.reportValidity()
      }
    })
  }

  makeDetailsVisible (details) {
    details.open = true
  }

  get validForm () {
    return this.element.checkValidity()
  }
}
