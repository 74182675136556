import AttachmentUpload from './attachment_upload_controller'

export default class extends AttachmentUpload {
  static targets = ['monthMF', 'yearMF', 'dateFR']
  FILE_SELECTED
  INPUT
  FILE_UPDATED_UI

  connect () {
    this.monthMFTarget.addEventListener('input', this.changeFileNameMF.bind(this))
    this.yearMFTarget.addEventListener('input', this.changeFileNameMF.bind(this))
    this.dateFRTarget.addEventListener('input', this.changeFileNameFR.bind(this))

    this.element.addEventListener('fileUploaded', this.validateSpecialCases.bind(this))
  }

  validateSpecialCases (event) {
    this.FILE_SELECTED = event.detail.abbreviationSelected
    this.INPUT = event.detail.input
    this.FILE_UPDATED_UI = event.detail.fileUpdatedUI

    if (this.FILE_SELECTED === 'MF') {
      this.assignNewFileName('MF', this.INPUT.dataset.file, `${this.month}-${this.year}`)
    }
    if (this.FILE_SELECTED === 'FR') {
      this.assignNewFileName('FR', this.INPUT.dataset.file, this.dateFRTarget.value)
    }
  }

  assignNewFileName (preffix, name, suffix) {
    const newName = this.formartFileName(preffix, name, suffix)
    this.FILE_UPDATED_UI.textContent = newName
  }

  changeFileNameMF () {
    this.changeCustomFileName('MF', `${this.month}-${this.year}`)
  }

  changeFileNameFR () {
    this.changeCustomFileName('FR', this.dateFRTarget.value)
  }

  changeCustomFileName (preffix, suffix) {
    if (this.INPUT) this.assignNewFileName(preffix, this.INPUT.dataset.file, suffix)
  }

  formartFileName (preffix, name, suffix) {
    if (name) {
      const arrayElements = name.split('.')
      const fileFormat = arrayElements[arrayElements.length - 1]
      const fileName = arrayElements.slice(0, arrayElements.length - 1).join(' ')

      return `${preffix} - ${fileName} - ${suffix}.${fileFormat}`
    }
  }

  get month () {
    return this.monthMFTarget.value
  }

  get year () {
    return this.yearMFTarget.value
  }
}
