import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'
import { Map } from '@/lib/heremap'

export default class extends Controller {
  static targets = ['map', 'stops', 'loading']

  initialize () {
    this.map = Map.fromController(this)
    this.map.initialize()
  }

  connect () {
    useMutation(this, { element: this.stopsTarget, attributes: true })
    this.map.render()
    this.hideLoading()
  }

  hideLoading () {
    setTimeout(() => {
      this.loadingTarget.classList.add('hidden')
    }, 1000)
  }

  mutate (entries) {
    for (const mutation of entries) {
      if (mutation.attributeName === 'data-coordinates') {
        this.map.render()
      }
    }
  }
}
