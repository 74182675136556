import { Controller } from '@hotwired/stimulus'
import { MaskInput } from 'maska'

export default class extends Controller {
  connect () {
    this.unmaskedInput = this.createUnmaskedInput()
    this.mask = new MaskInput(this.element, {
      number: {
        locale: 'us',
        fraction: 2,
        unsigned: true
      },
      postProcess: (val) => val ? `$${val}` : '',
      onMaska: (value) => {
        this.unmaskedInput.value = value.unmasked
      }
    })
  }

  createUnmaskedInput () {
    const input = Object.assign(document.createElement('input'), {
      type: 'hidden',
      name: this.element.name,
      value: this.element.value
    })

    this.element.insertAdjacentElement('afterend', input)
    return input
  }

  disconnect () {
    this.mask?.destroy()
    this.unmaskedInput?.remove()
  }
}
