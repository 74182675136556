import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  validateInput ({ target }) {
    if (target.value === 'permanently delete') {
      this.submitButtonTarget.disabled = false
    }
  }
}
