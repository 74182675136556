import { Controller } from '@hotwired/stimulus'
import { toggle } from 'el-transition'

export default class extends Controller {
  static targets = ['formMarkAsCompleted', 'rowFilters']

  markAsCompleted () {
    this.formMarkAsCompletedTarget.submit()
  }

  showFilters () {
    toggle(this.rowFiltersTarget)
  }
}
