import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['radio', 'panel']

  initialize () {
    this.showPanel()
  }

  change ({ target }) {
    this.selected = target.value
  }

  showPanel () {
    this.panelTargets.forEach((panel) => {
      if (panel.dataset.name === this.selected) {
        panel.hidden = false
      } else {
        panel.hidden = true
      }
      this.togglePanelElements(panel)
    })
  }

  togglePanelElements (panel) {
    panel.querySelectorAll('input, select').forEach((element) => {
      element.disabled = panel.hidden
      // Don't require checkboxes https://stackoverflow.com/q/6218494/9688210
      if (element.type !== 'checkbox') element.required = !panel.hidden
    })
  }

  get selected () {
    return this.data.get('selected')
  }

  set selected (value) {
    this.data.set('selected', value)
    this.showPanel()
  }
}
