import Autocomplete from './autocomplete_controller'
import { search } from '@/lib/heremap/service'
import { discover } from '@/lib/heremap/helpers'

export default class extends Autocomplete {
  static targets = ['latitude', 'longitude']

  onInputChange = () => {
    this.element.removeAttribute('value')

    const query = this.inputTarget.value.trim()
    if (query && query.length >= this.minLengthValue) {
      this.fetchResults(query)
    } else {
      this.hideAndRemoveOptions()
    }
  }

  onInputBlur = () => {
    if (this.mouseDown) return
    if (this.hiddenTarget.value) { this.inputTarget.value = this.hiddenTarget.value }
    this.close()
  }

  commit (selected) {
    if (selected.getAttribute('aria-disabled') === 'true') return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.close()
      return
    }

    const textValue = selected.getAttribute('data-autocomplete-label') || selected.textContent.trim()
    const value = selected.getAttribute('data-autocomplete-value') || textValue

    this.inputTarget.value = textValue
    this.latitudeTarget.value = selected.getAttribute('data-autocomplete-latitude')
    this.longitudeTarget.value = selected.getAttribute('data-autocomplete-longitude')

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value
      this.hiddenTarget.dispatchEvent(new Event('input'))
      this.hiddenTarget.dispatchEvent(new Event('change'))
    } else {
      this.inputTarget.value = value
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(
      new CustomEvent('autocomplete.change', {
        bubbles: true,
        detail: { value, textValue, selected }
      })
    )
  }

  fetchRemoteResults = async (query) => {
    return await this.doFetch(query)
  }

  doFetch = async (query) => {
    const parsedQuery = query.trim().toLowerCase()

    const { items } = await discover(search, parsedQuery)

    const formattedItems = items.map(({ address, position }) => {
      return {
        address,
        addressLabel: address.label.split(', ').slice(0, -1).join(', ').trim(),
        latitude: position.lat,
        longitude: position.lng
      }
    })

    return formattedItems.map(item => this.decorateResult(item)).join('\n')
  }

  decorateResult = (result) => {
    return `
      <li
        data-autocomplete-value="${result.addressLabel}"
        data-autocomplete-label="${result.addressLabel}"
        data-autocomplete-latitude="${result.latitude}"
        data-autocomplete-longitude="${result.longitude}"
        role="option"
        class="p-3 cursor-pointer text-gray-800 font-semibold hover:bg-gray-100 hover:text-blue-600"
      >
        ${result.address.houseNumber || ''} ${result.address.street || ''}
        <span class="font-normal hover:text-blue-600">${result.address.city || ''} ${result.address.stateCode || ''} ${result.address.postalCode || ''}</span>
      </li>
    `
  }
}
