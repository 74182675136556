import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    verificationPhone: String,
    resendRoute: String,
    resendCount: Number
  }

  static targets = ['message', 'resendButton']

  connect () {
    this.counterNumber = this.resendCountValue
    this.resendCode = false
    this.sentCode = false
    this.updateMessage()
    this.startInterval()
  }

  startInterval () {
    this.interval = setInterval(() => {
      if (this.counterNumber === 0) {
        this.resendCode = true
        clearInterval(this.interval)
        this.updateMessage()
      } else {
        this.counterNumber -= 1
        this.updateMessage()
      }
    }, 1000)
  }

  async resendCodeNow () {
    this.counterNumber = this.resendCountValue
    this.resendCode = false
    this.sentCode = true
    this.updateMessage()

    await post(this.resendRouteValue, {
      body: JSON.stringify({ phone: this.verificationPhoneValue }),
      contentType: 'application/json'
    })

    setTimeout(() => {
      this.sentCode = false
      this.updateMessage()
      this.startInterval()
    }, 3000)
  }

  updateMessage () {
    if (this.sentCode) {
      this.messageTarget.innerHTML = '<span class="text-lg font-semibold text-gray-60">The code has been sent!</span>'
    } else if (this.resendCode) {
      this.messageTarget.innerHTML = this.resendButtonContent
    } else {
      this.messageTarget.innerHTML = `You can resend the code in <span class="font-semibold text-m-blue"> 00:${this.counterNumber < 10 ? '0' : ''}${this.counterNumber}</span>`
    }
  }

  // Private

  get resendButtonContent () {
    return this.resendButtonTarget.innerHTML
  }
}
