import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['boxes']

  show () {
    const element = this.boxesTarget
    element.classList.contains('hidden') ? element.classList.remove('hidden') : element.classList.add('hidden')
  }
}
