import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['periodSelect', 'categoriesContainer']
  static values = { newRecord: Boolean }

  connect () {
    if (this.newRecordValue) this.disableCategoriesFormControls = true
  }

  accountChanged ({ detail }) {
    const accountId = detail.value

    if (accountId) {
      this.#fetchPeriods(accountId)
    } else {
      this.#resetAndDisablePeriodSelect()
    }
  }

  periodChanged ({ target }) {
    if (target.value) {
      this.disableCategoriesFormControls = false
    } else {
      this.disableCategoriesFormControls = true
    }
  }

  // Private

  #fetchPeriods (accountId) {
    get('/super_admin/safety_ratings/account_periods', {
      query: { account_id: accountId },
      responseKind: 'turbo-stream'
    })
  }

  #resetAndDisablePeriodSelect () {
    this.periodSelectTarget.disabled = true
    this.periodSelectTarget.value = ''
    this.periodSelectTarget.dispatchEvent(new Event('change'))
  }

  // eslint-disable-next-line
  set disableCategoriesFormControls (value) {
    this.categoriesFormControls.forEach((element) => {
      element.disabled = value
    })
  }

  get categoriesFormControls () {
    return this.categoriesContainerTarget.querySelectorAll('input, select')
  }
}
