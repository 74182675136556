import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(utc)

export function parseUtcDateTime (str) {
  return dayjs(str)
}

export function datetimeToUtc (str) {
  return dayjs(str).utc().format()
}

export function humanizedDuration (durationInSeconds) {
  if (!durationInSeconds) {
    return '-'
  } else {
    return dayjs.duration(durationInSeconds, 'seconds').format('H[h] m[m]')
  }
}

export function debounce (fn, delay) {
  let timeoutId = null
  return function () {
    clearTimeout(timeoutId)
    const args = arguments
    const that = this
    timeoutId = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}
