import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'content', 'active']

  connect () {
    this.resizeTextarea()
    this.contentTarget.addEventListener('input', this.resizeTextarea.bind(this))
  }

  toggleReadOnlyTerm () {
    this.contentTarget.readOnly = !this.checkboxTarget.checked
    this.activeTarget.value = this.checkboxTarget.checked
  }

  toggleDisableTerm () {
    this.contentTarget.disabled = !this.checkboxTarget.checked
  }

  resizeTextarea () {
    this.contentTarget.style.overflow = 'hidden'
    this.contentTarget.style.height = 'auto'
    this.contentTarget.style.height = `${this.contentTarget.scrollHeight}px`
  }
}
