import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default class extends Controller {
  connect () {
    this.initializeFlatpickr()
  }

  initializeFlatpickr () {
    this.flatpickr = flatpickr(this.element, {
      dateFormat: 'Y-m',
      defaultDate: this.element.value,
      disableMobile: true,
      plugins: [new monthSelectPlugin({ // eslint-disable-line new-cap
        shorthand: true,
        dateFormat: 'Y-m'
      })]
    })
  }
}
