import { BridgeComponent, BridgeElement } from '@hotwired/strada'

export default class extends BridgeComponent {
  static component = 'menu'
  static targets = ['title', 'item']

  show () {
    this.#notifyBridgeToDisplayMenu()
  }

  #notifyBridgeToDisplayMenu () {
    const title = new BridgeElement(this.titleTarget).title
    const items = this.makeMenuItems(this.itemTargets)

    this.send('display', { title, items }, message => {
      const selectedIndex = message.data.selectedIndex
      const selectedItem = new BridgeElement(this.itemTargets[selectedIndex])

      selectedItem.click()
    })
  }

  makeMenuItems (elements) {
    return elements.map((element, index) => this.menuItem(element, index))
  }

  menuItem (element, index) {
    const bridgeElement = new BridgeElement(element)

    return {
      title: bridgeElement.title,
      index
    }
  }
}
