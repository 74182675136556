import { Controller } from '@hotwired/stimulus'
import { datetimeToUtc, parseUtcDateTime } from '@/lib/helpers'

export default class extends Controller {
  static targets = ['input', 'datepicker', 'timepicker']

  connect () {
    customElements.whenDefined('duet-date-picker').then(() => {
      if (this.inputTarget.value) {
        this.populateDateAndTimePicker()
      }
    })
  }

  datepickerChanged ({ target }) {
    if (target.value && this.time) { this.inputTarget.value = datetimeToUtc(`${target.value} ${this.time}`) }
  }

  timepickerChanged ({ target }) {
    if (target.value && this.date) { this.inputTarget.value = datetimeToUtc(`${this.date} ${target.value}`) }
  }

  populateDateAndTimePicker () {
    const datetime = parseUtcDateTime(this.inputTarget.value)
    this.date = datetime.format('YYYY-MM-DD')
    this.time = datetime.format('HH:mm:ss')
  }

  set date (value) {
    this.datepickerTarget.value = value
  }

  set time (value) {
    this.timepickerTarget.value = value
  }

  get time () {
    return this.timepickerTarget.value
  }

  get date () {
    return this.datepickerTarget.value
  }
}
