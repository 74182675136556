import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['expanded']
  static values = {
    clearInput: { type: Boolean, default: true }
  }

  connect () {
    this.synchronizeControlledTarget()
  }

  toggle () {
    this.expanded = !this.expanded
  }

  // Private

  synchronizeControlledTarget () {
    if (this.hasExpandedClass) {
      this.controlledTarget.classList.toggle(this.expandedClass, this.expanded)
    } else {
      this.controlledTarget.hidden = !this.expanded
    }

    this.controlledTarget.querySelectorAll('input, select').forEach((element) => {
      if (this.expanded) {
        element.setAttribute('required', this.expanded)
      } else {
        element.removeAttribute('required')
        if (this.clearInputValue) {
          element.value = ''
        }
      }
    })
  }

  get controlledTarget () {
    return document.getElementById(this.element.getAttribute('aria-controls'))
  }

  set expanded (expanded) {
    this.element.setAttribute('aria-expanded', expanded)

    this.synchronizeControlledTarget()
  }

  get expanded () {
    return this.element.getAttribute('aria-expanded') === 'true'
  }
}
