import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select']
  inputUI = ''
  inputDB = ''

  connect () {
    setTimeout(() => {
      this.inputUI = document.querySelector('.form-control')
      this.inputDB = document.getElementById('account_trial_expired_at')
      this.updateInputState()
    }, 500)
  }

  statusChange ({ target }) {
    this.updateInputState(target.value)
  }

  updateInputState (value = this.selectTarget.value) {
    const isTrial = value === 'trial'

    this.inputUI.disabled = !isTrial
    this.inputUI.required = isTrial

    if (!isTrial) {
      this.inputDB.value = ''
    }
  }
}
