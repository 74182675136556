import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { vehicleId: Number }

  categoryChanged ({ target }) {
    const categoryName = this.selectedCategoryText(target)

    if (categoryName) {
      this.fetchTitleInfo(categoryName)
    }
  }

  fetchTitleInfo (category) {
    get(`/vehicles/${this.vehicleIdValue}/maintenance_actions/unscheduled_categories`, {
      query: { category_name: category },
      responseKind: 'turbo-stream'
    })
  }

  selectedCategoryText (target) {
    const selectedIndex = target.selectedIndex
    return target.options[selectedIndex].text
  }
}
