import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'inputPercentage', 'inputMile']

  connect () {
    this.validateSelectedValue()
  }

  changeTypeSelected () {
    if (this.selectTarget.value === 'per_mile') {
      this.inputMileTarget.disabled = false
      this.inputPercentageTarget.disabled = true
    } else {
      this.inputMileTarget.disabled = true
      this.inputPercentageTarget.disabled = false
    }

    if (this.selectTarget.value === '') {
      this.inputMileTarget.disabled = true
      this.inputPercentageTarget.disabled = true
    }
  }

  validateSelectedValue () {
    if (this.selectTarget.value === 'per_mile') this.inputMileTarget.disabled = false
    if (this.selectTarget.value === 'percentage') this.inputPercentageTarget.disabled = false
  }
}
